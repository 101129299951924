import { Action, action, thunk, Thunk } from 'easy-peasy';
import { get } from 'lodash';
import ErrorHandler from '../../web/utils/ErrorHandler';
import http from '../../web/utils/http';
import Notification from '../../web/utils/Notification';
export interface IPostModel {
    /*****..........@...ANY TYPE...@.......*****/
    PostModel: Array<Object>;

    /*****..........@...ACTION TYPE...@.......*****/
    setPostModel: Action<IPostModel, any>;

    /*****..........@...THUNK TYPE...@.......*****/
    loadPostModel: Thunk<IPostModel, any>;
    savePostModel: Thunk<IPostModel, any>;
    updatePostModel: Thunk<IPostModel, any>;
    deletePostModel: Thunk<IPostModel, any>;
}

const PostModel: IPostModel = {
    PostModel: [],

    /*****..........@...ACTION TYPE...@.......*****/
    setPostModel: action((state, payload) => {
        state.PostModel = payload;
    }),

    /*****..........@...THUNK TYPE...@.......*****/
    loadPostModel: thunk(async (actions, data) => {
       
            let params ={
                'page': get(data,'page') || 1,
                'pageSize': get(data,'pageSize') || 10,
                'title': get(data,'title') || "",
                'category': get(data,'category') || "",
                'status': get(data,'status') || ""
            }
        
        // const response = await http().get('posts',{params}).then((res)=>{
        //     const { data } = res;
        //     actions.setPostModel(data);
        // }).catch((error)=>{
        //     Notification.error("Unable to load data");
        // });
        const rspn = await http().get('posts',{params});
        // console.log(JSON.stringify(rspn)+'azaa');
        actions.setPostModel(rspn.data);
        return rspn.data

    }),

    savePostModel: thunk(async (actions, data) => {
        const response = await http({ "Content-Type": "multipart/form-data" }).post('posts', data).then((res) => {
            const { data } = res;
            actions.loadPostModel(actions);
            Notification.success("Changes save successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),

    updatePostModel: thunk(async (actions, data) => {
        const response = await http({ "Content-Type": "multipart/form-data" }).put('posts', data).then((res) => {
            const { data } = res;
            // actions.loadPostModel(actions);
            Notification.success("Changes updated successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),

    deletePostModel: thunk(async (actions, id) => {
        const response = await http().delete(`posts/${id}`).then((res) => {
            const { data } = res;
            actions.loadPostModel(actions);
            Notification.success("deleted successfully");
        }).catch((error) => {
            ErrorHandler(error);
            return new Promise((resolve, reject) =>reject("error"));
        })
    }),



}

export default PostModel;